<template>
  <footer class="bg-gray-100 shadow-md mt-auto">
    <div class="container mx-auto px-4">
      <div class="py-8">
        <div class="flex flex-wrap justify-between">
          <!-- Logo and company info -->
          <div class="w-full md:w-1/4 mb-6 pr-8 md:mb-0">
            <img src="https://cdn.arrify.com/wp-content/uploads/2022/05/cropped-Arrify_Text_small.png" alt="Arrify Logo" class="h-8 w-auto mb-4">
            <p class="text-sm text-gray-600 text-justify">We are a small team providing Salesforce development and consulting services to small and medium-sized businesses across the globe.</p>
          </div>

          <!-- Quick Links -->
          <div class="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 class="text-lg font-semibold mb-4">Quick Links</h3>
            <ul class="space-y-2">
              <li><a href="/" class="text-sm text-gray-600 hover:text-gray-900">Home</a></li>
              <li><a href="/about" class="text-sm text-gray-600 hover:text-gray-900">About</a></li>
              <li><a href="/careers" class="text-sm text-gray-600 hover:text-gray-900">Careers</a></li>
              <li><a href="/contact" class="text-sm text-gray-600 hover:text-gray-900">Contact</a></li>
            </ul>
          </div>

          <!-- Contact Info -->
          <div class="w-full md:w-1/4 mb-6 md:mb-0">
            <h3 class="text-lg font-semibold mb-4">Contact Us</h3>
            <p class="text-sm text-gray-600 mb-2">S5, 1st Floor, IT Park Road</p>
            <p class="text-sm text-gray-600 mb-2">Sitapura Industrial Area, Jaipur, Rajasthan 302022</p>
            <p class="text-sm text-gray-600">Email: support[at]arrify[dot]com</p>
          </div>

          <!-- Social Media Links -->
          <div class="w-full md:w-1/4">
            <h3 class="text-lg font-semibold mb-4">Follow Us</h3>
            <div class="flex space-x-4">
              <!--<a href="#" class="text-gray-500 hover:text-gray-900">
               <span class="sr-only">Facebook</span>
               <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                 <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
               </svg>
             </a>
             <a href="#" class="text-gray-500 hover:text-gray-900">
               <span class="sr-only">Twitter</span>
               <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                 <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
               </svg>
             </a>
             <a href="#" class="text-gray-500 hover:text-gray-900">
               <span class="sr-only">GitHub</span>
               <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                 <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
               </svg>
             </a> -->

              <!-- Facebook -->
              <a href="https://www.facebook.com/Arrify.ITS" target="_blank" class="text-gray-500 hover:text-gray-900" aria-label="Facebook">
                <i class="fab fa-facebook-f h-6 w-6"></i>
              </a>
              <!-- Twitter -->
              <a href="https://twitter.com/arrifyits" target="_blank" class="text-gray-500 hover:text-gray-900" aria-label="Twitter">
                <i class="fab fa-twitter h-6 w-6"></i>
              </a>
              <!-- LinkedIn -->
              <a href="https://www.linkedin.com/company/arrify/" target="_blank" class="text-gray-500 hover:text-gray-900" aria-label="LinkedIn">
                <i class="fab fa-linkedin-in h-6 w-6"></i>
              </a>
              <!-- Medium -->
              <a href="https://medium.com/@arrify-blog" target="_blank" class="text-gray-500 hover:text-gray-900" aria-label="Medium">
                <i class="fab fa-medium-m h-6 w-6"></i>
              </a>
              <!-- Pinterest -->
              <a href="https://www.pinterest.com/Arrify1/" target="_blank" class="text-gray-500 hover:text-gray-900" aria-label="Pinterest">
                <i class="fab fa-pinterest-p h-6 w-6"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="border-t border-gray-200 py-4">
        <p class="text-sm text-center text-gray-500">&copy; {{ currentYear }} Arrify. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
